svg.progress-bar-round circle {
  transition: 0.5s;
}
.percentage {
  font-size: 46px;
}
.loading {
  font-size: 16px;
}
.progress-bar-round {
  margin-top: 15px;
}